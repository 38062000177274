@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);

// Buttons
$cta-color: #68a3e0;
$cta-text-color: #ffffff;

// Background
$default-background-color: #ffffff;
$hero-section-background: #e6e6e6;
$footer-background-color: #000000;
$testimonial-background-color: #f3f3f3;

// Text
$primary-text-color: #000000;
$secondary-text-color: #909090;

$hero-section-header-text-color: #002941;
$hero-section-header-text-size: 80px;
$hero-section-header-text-line-heigth: 100px;
$hero-section-header-text-weight: bolder;
$hero-section-body-text-color: #4B4752;
$hero-section-body-text-size: 20px;
$hero-section-body-text-line-heigth: 32px;

$section-header-title-text-size: 45px;
$section-header-title-text-color: #002941;
$section-header-title-text-weight: bolder;

$section-content-title-text-size: 28px;
$section-content-text-size: 16px;
$section-content-text-line: 1.6;

$footer-text-color: #FFFFFF;
$footer-content-text-size: 12px;
$footer-title-text-weight: bolder;
$footer-content-text-line: 1.6;

// Fonts
$font-default: 'Nunito Sans', 'Roboto', Helvetica, sans-serif;

// Icons
$section-content-icon-height: 48px;