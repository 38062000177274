.hero-header-text{
    font-size: $hero-section-header-text-size;
    color: $hero-section-header-text-color;
    line-height: $hero-section-header-text-line-heigth;
    font-weight: $hero-section-header-text-weight;
}

.hero-body-text {
    font-size: $hero-section-body-text-size;
    color: $hero-section-body-text-color;
    line-height: $hero-section-body-text-line-heigth;
}

.hero-section-background {
    height: 686px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;
}

.hero-section-background-color {
    background-color: $hero-section-background;
}

.hero-section-layout {
    padding-top: 200px;
}

.cta-button {
    width: fit-content;
    background-color: $cta-color;
    padding-left: 32px;
    padding-right: 32px;
    height: 44px;
    border-radius: 10px;
    color: $cta-text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}