.cta-header-button {
    background-color: $cta-color;
    width: 142px;
    height: 44px;
    border-radius: 10px;
    color: $cta-text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}