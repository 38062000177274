body,
html,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: $font-default;
}

a {
    color: $primary-text-color;
    text-decoration: none;
}

.border-box{
    box-sizing: border-box
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 50%;
}

.quarter-width {
    width: 25%;
}

.percent-45-width {
    width: 45%;
}

.percent-55-width {
    width: 55%;
}

.content-max-width {
    max-width: 1440px;
}

.App-logo {
    height: 5vmin;
    pointer-events: none;
    padding: 2vmin;
}

// Text

.text-aligment-center {
    text-align: center;
}

// Flexbox
.in-line-content {
    display: flex;
    flex-direction: row;
}

.column-content {
    display: flex;
    flex-direction: column;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

.content-vertically-center {
    display: flex;
    align-items: center;
}

.content-align-top {
    display: flex;
    align-items: flex-start;
}

.justify-to-right {
    display: flex;
    justify-content: flex-end;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.flex-warp {
    flex-wrap: wrap;
}

// Margins and paddings

.margin-top-32 {
    margin-top: 32px;
}

.margin-top-64 {
    margin-top: 64px;
}

.margin-bottom-32 {
    margin-bottom: 32px;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.margin-left-16 {
    margin-left: 16px;
}

.margin-left-right-16 {
    margin-left: 16px;
    margin-right: 16px;
}

.padding-16 {
    padding: 16px;
}

.padding-32 {
    padding: 32px;
}

.padding-left-16 {
    padding-left: 16px;
}

.padding-rigth-16 {
    padding-right: 16px;
}

.padding-top-32 {
    padding-top: 32px;
}

.padding-bottom-32 {
    padding-bottom: 32px;
}