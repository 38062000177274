.section-title {
    font-size: $section-header-title-text-size;
    font-weight: $section-header-title-text-weight;
    color: $section-header-title-text-color;
}

.feature-title {
    font-weight: $section-header-title-text-weight;
    color: $section-header-title-text-color;
    font-size: $section-content-title-text-size ;
}

.feature-content {
    font-size: $section-content-text-size;
    line-height: $section-content-text-line;
}

.feature-icon {
    height: $section-content-icon-height;
}