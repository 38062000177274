.box-container {
    background-color: $testimonial-background-color;
    padding: 5% 10% 5% 10%;
    border-radius: 50px;
}

.testimonial-section-title {
    font-size: $section-header-title-text-size;
    font-weight: $section-header-title-text-weight;
    color: $section-header-title-text-color;
}

.testimonial-box-container {
    position: relative;
    background-color: $default-background-color;
    line-height: $footer-content-text-line;
    padding: 5% 5% 5% 5%;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.379));
}

.testimonial-box-container::after {
	content: "";
	position: absolute;
	height: 50px;
	width: 40px;
	bottom: -49px;
	left: 45px;
	background-color: $default-background-color;
	clip-path: polygon(20% 0, 100% 0, 0 100%);
}

.dot {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.round-avatar {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: 5px;
}

.client-logo {
    height: 48px;
}