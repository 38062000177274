.footer-title {
    color: $footer-text-color;
    font-weight: $footer-title-text-weight;
}

.footer-content {
    color: $footer-text-color;
}

.footer-background {
    background-color: $footer-background-color;
}

.footer-logo {
    height: 10vmin;
}